import React, { useMemo, useReducer } from 'react';
import { graphql } from "gatsby";

import LayoutBlog from '../components/LayoutBlog';
import Hero from '../components/sections/Hero';
import Posts from '../components/sections/Posts';

import { composePosts } from '../utils';

const POSTS_PER_PAGE = 7;

const postsReducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_MORE': {
      const pageStart = state.currentPage * POSTS_PER_PAGE;
      const pageEnd = pageStart + POSTS_PER_PAGE;
      const newPosts = state.allPosts.slice(0, pageEnd);
      return {
        ...state,
        currentPage: state.currentPage + 1,
        pagePosts: newPosts,
        hasNextPage: state.allPosts.length > (state.currentPage + 1) * POSTS_PER_PAGE,
      };
    }
    case 'SET_CURRENT_PAGE': {
      const requestedPage = action.payload;
      if (state.allPosts.length < requestedPage * POSTS_PER_PAGE) {
        return {
          ...state,
          currentPage: 1,
          pagePosts: state.allPosts.slice(0, POSTS_PER_PAGE),
          hasNextPage: state.allPosts.length > 1 * POSTS_PER_PAGE,
        };
      }

      const pageStart = state.currentPage * POSTS_PER_PAGE;
      const pageEnd = pageStart + POSTS_PER_PAGE;
      const newPosts = state.allPosts.slice(0, pageEnd);

      return {
        ...state,
        currentPage: requestedPage,
        pagePosts: newPosts,
        hasNextPage: state.allPosts.length > (requestedPage + 1) * POSTS_PER_PAGE,
      };
    }
    default:
      return state;
  }
}

const Blog = ({ data: { allMarkdownRemark } }) => {
  const allPosts = useMemo(() => composePosts(allMarkdownRemark.nodes), [allMarkdownRemark]);
  const [postsState, dispatchPosts] = useReducer(postsReducer, {
    allPosts,
    pagePosts: allPosts.slice(0, POSTS_PER_PAGE),
    currentPage: 1,
    hasNextPage: allPosts.length > POSTS_PER_PAGE,
  });

  const handleLoadMore = () => {
    dispatchPosts({ type: 'LOAD_MORE' });
  }

  return (
    <LayoutBlog title="Blog">
      <Hero
        title="Jetic Blog"
        description="iPaaS Insights, integration tutorials, tips and best practices"
      />
      <Posts
        posts={postsState.pagePosts}
        hasNextPage={postsState.hasNextPage}
        loadMoreHandler={handleLoadMore}
      />
    </LayoutBlog>
  );
};

const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: {order: DESC, fields: [frontmatter___date]}
      filter: {frontmatter: {type: {eq: "blog"}}}
      limit: 1000
    ) {
      nodes {
        excerpt(format: PLAIN, pruneLength: 180)
        frontmatter {
          type
          title
          slug
          date(formatString: "MMMM DD")
          image {
            childImageSharp {
              gatsbyImageData(width: 600)
            }
          }
          author {
            frontmatter {
              name
              avatar {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 56)
                }
              }
              slug
            }
          }
        }
      }
    }
  }
`;

export { pageQuery };

export default Blog;
